import React from "react";

const Highlights = () => {
    return(
        <section className="page-section" id="commitment">
            <div className="container px-4 px-lg-5">
            <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Our Commitment</h2>
           {/* Icon Divider*/}
            <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                <div className="divider-custom-line"></div>
            </div>
                <div className="row gx-4 gx-lg-5">
                    <div className="col-lg-2 col-md-6 text-center">
                        <div className="mt-5">
                            <div className="mb-2"><i className="bi bi-bag-heart fs-1 text-primary"></i></div>
                            <h3 className="h4 mb-2">5000+</h3>
                            <p className="text-muted mb-0">Products</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 text-center">
                        <div className="mt-5">
                            <div className="mb-2"><i className="bi bi-emoji-heart-eyes fs-1 text-primary"></i></div>
                            <h3 className="h4 mb-2">1000+</h3>
                            <p className="text-muted mb-0">Happy Customer's</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 text-center">
                        <div className="mt-5">
                            <div className="mb-2"><i className="bi bi-calendar-heart fs-1 text-primary"></i></div>
                            <h3 className="h4 mb-2">300+</h3>
                            <p className="text-muted mb-0">Daily Customer's</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 text-center">
                        <div className="mt-5">
                            <div className="mb-2"><i className="bi bi-cart-check fs-1 text-primary"></i></div>
                            <h3 className="h4 mb-2">100%</h3>
                            <p className="text-muted mb-0">Best Quality</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 text-center">
                        <div className="mt-5">
                            <div className="mb-2"><i className="bi bi-house-heart fs-1 text-primary"></i></div>
                            <h3 className="h4 mb-2">30 Min</h3>
                            <p className="text-muted mb-0">Home Delivery</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 text-center">
                        <div className="mt-5">
                            <div className="mb-2"><i className="bi bi-alarm fs-1 text-primary"></i></div>
                            <h3 className="h4 mb-2">7 AM - 9 PM</h3>
                            <p className="text-muted mb-0">Open Hours</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Highlights;