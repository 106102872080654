import React from "react"
import Navigation from "./Navigation"
import Herocontent from "./Herocontent"
import Gallery from "./Gallery"
import Aboutus from "./Aboutus"
import Footer from "./Footer"
import Contactus from "./Contactus"
import GalleryModal from "./GalleryModal"
import Highlights from "./Highlights"

const App = () => {
return (
  <div>
    <Navigation />
    <Herocontent />    
    <Gallery />
    <Highlights />
    <Aboutus />
    <Contactus />
    <Footer />
    <GalleryModal />
  </div>
)
}

export default App;