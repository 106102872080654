import React from "react";
import logo from "../src/assets/images/logo.png"

const Navigation = () =>{
return(
<nav className="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
        <div className="container">
            <a className="navbar-brand" href="/"><img src={logo} alt="N Mart" /></a>
            <button className="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button"
                data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
                aria-expanded="false" aria-label="Toggle navigation">
                <i className="fas fa-bars"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded"
                            href="#home">Home</a></li>
                            <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded"
                            href="#products">Products</a></li>
                            <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded"
                            href="#commitment">Commitment</a></li>
                    <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded"
                            href="#about">About Us</a></li>
                    <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded"
                            href="#contact">Contact Us</a></li>
                             <li className="nav-item mx-0 mx-lg-1"> <a className="btn btn-outline-light btn-social mx-1" href="https://chat.whatsapp.com/DwGGRSs1lKPLwQDEWa1Gdx" target="_blank"  rel="noopener noreferrer"><i
                            className="fab fa-fw fa-brands fa-whatsapp"></i></a><a className="btn btn-outline-light btn-social mx-1" href="https://www.instagram.com/nmartcjm/" target="_blank"  rel="noopener noreferrer"><i className="fab fa-fw fa-brands fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    </nav>
)
}
export default Navigation;