import React from "react";

const Aboutus = () => {
    return (
        <section className="page-section bg-light text-white mb-0" id="about">
        <div className="container">
           {/* About Section Heading*/}
            <h2 className="page-section-heading text-center text-uppercase text-dark">About US</h2>
           
           {/* Icon Divider*/}
            <div className="divider-custom divider-dark">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                <div className="divider-custom-line"></div>
            </div>
            <h4 className="text-center text-danger mb-5">Welcome to Chinnaganjam's First Supermarket - Your One-Stop Shop for Convenience and Savings!</h4>
           {/* About Section Content*/}
            <div className="row">
            <div className="col-lg-12 text-center text-black mb-5">
                <h4><u>Who we are</u></h4>
                    <p className="lead ">At N Mart, we are committed to making your shopping experience as convenient and cost-effective as possible. Located in the heart of Chinnaganjam Mandal, Bapatla District, Andhra Pradesh, we are proud to be the first superstore in this vibrant community.</p>
                </div>
                <div className="col-lg-6 text-black">
                    <h5><i class="bi bi-star-fill"></i> Convenience at Your Doorstep</h5>
                    <p className="lead mb-4">We understand the value of your time and money. Say goodbye to long journeys to Chirala and Ongole for your daily essentials. With N Mart, you can now find everything you need right in your neighborhood. Save those valuable hours for more important things in life.</p>
                    <h5><i class="bi bi-star-fill"></i> Wide Range of Products</h5>
                    <p className="lead mb-4">Our supermarket boasts an extensive selection of products, from fresh fruits and vegetables to pantry staples, household items, and more. We source the finest quality goods to ensure that you get the best value for your money.</p>
                    <h5><i class="bi bi-star-fill"></i> Home Delivery</h5>
                    <p className="lead mb-5">To make your shopping experience even more convenient, we offer home delivery services. Sit back and relax while our team ensures that your groceries are delivered straight to your doorstep. No more hassle, no more waiting in long queues – just effortless shopping.</p>
                </div>
                <div className="col-lg-6 text-black">
                <h5><i class="bi bi-star-fill"></i> Competitive Prices</h5>
                    <p className="lead mb-4">We believe in offering competitive prices without compromising on quality. Our commitment to affordability means that you can shop with confidence, knowing you're getting the best deals in town. <br /><br /></p>
                    <h5><i class="bi bi-star-fill"></i> Friendly Customer Service</h5>
                    <p className="lead mb-4">Our dedicated team of staff is always ready to assist you. Whether you need help finding a product or have a question about our services, we are here to make your shopping experience pleasant and stress-free.<br /><br /><br /></p>
                    <h5><i class="bi bi-star-fill"></i> Community Engagement</h5>
                    <p className="lead mb-5">N Mart is more than just a store; we're part of the community. We actively engage with local events and initiatives, supporting the growth and development of Chinnaganjam Mandal.</p>
                </div>
                <div className="col-lg-12 text-center text-black mb-2">
                <h4><u>Join Us Today</u></h4>
                    <p className="lead ">Experience the convenience and savings of shopping at N Mart. We are proud to serve the residents of Chinnaganjam Mandal and the surrounding areas. Come visit us or take advantage of our home delivery service and discover the difference of shopping at your neighborhood superstore.</p>
                </div>
            </div>
           {/* About Section Button*/}
             {/*<div className="text-center mt-4">
                <a className="btn btn-xl btn-outline-light" href="https://startbootstrap.com/theme/freelancer/">
                    <i className="fas fa-download me-2"></i>
                    Free Download!
                </a>
    </div>*/}
        </div>
    </section>
    )
}

export default Aboutus;