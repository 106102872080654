import React from "react";
import nmart0 from "../src/assets/images/Nmart-Photos/nmart0.jpg"
import nmart2 from "../src/assets/images/Nmart-Photos/nmart2.jpg"
import nmart7 from "../src/assets/images/Nmart-Photos/nmart7.jpg"
import nmart8 from "../src/assets/images/Nmart-Photos/nmart8.jpg"
import nmart10 from "../src/assets/images/Nmart-Photos/nmart10.jpg"

const Herocontent = () => {
    return(
        <div id="home" className="bg-danger">
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner">
            <div className="carousel-item active">
            <img  src={nmart0} alt="nmart0" />

            </div>
            <div className="carousel-item">
            <img  src={nmart2} alt="nmart2" />
            <div className="carousel-caption d-none d-md-block">
            <h1 style={{fontSize:"60px"}}>Welcome to N Mart</h1>
            <h4>Chinnaganjam's First Supermarket</h4>
            </div>
            </div>
            <div className="carousel-item">
            <img  src={nmart7} alt="nmart7" />

            </div>
            <div className="carousel-item">
            <img  src={nmart8} alt="nmart8" />

            </div>
            <div className="carousel-item">
            <img  src={nmart10} alt="nmart10" />

            </div>
            </div>
            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
            </a>
            </div>
            </div>
    )
}

export default Herocontent;