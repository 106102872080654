import React from "react";
import product1 from "../src/assets/images/Products/product1.png"
import product2 from "../src/assets/images/Products/product2.png"
import product3 from "../src/assets/images/Products/product3.png"
import product4 from "../src/assets/images/Products/product4.png"
import product5 from "../src/assets/images/Products/product5.png"
import product6 from "../src/assets/images/Products/product6.png"
import product7 from "../src/assets/images/Products/product7.png"
import product8 from "../src/assets/images/Products/product8.png"
import product9 from "../src/assets/images/Products/product9.png"
import product10 from "../src/assets/images/Products/product10.png"
import product11 from "../src/assets/images/Products/product11.png"
import product12 from "../src/assets/images/Products/product12.png"


const GalleryModal = () => {
    return (
       <div className="text-center">
        <div className="portfolio-modal modal fade" id="portfolioModal1" tabIndex="-1" aria-labelledby="portfolioModal1"
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div className="modal-body text-center pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {/* Portfolio Modal - Title*/}
                                <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Product Details</h2>
                                {/* Icon Divider*/}
                                <div className="divider-custom">
                                    <div className="divider-custom-line"></div>
                                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                    <div className="divider-custom-line"></div>
                                </div>
                                {/* Portfolio Modal - Image*/}
                                <img className="img-fluid rounded mb-5" src={product1} alt="product1" />
                                <button className="btn btn-primary" data-bs-dismiss="modal">
                                    <i className="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Portfolio Modal 2*/}
    <div className="portfolio-modal modal fade" id="portfolioModal2" tabIndex="-1" aria-labelledby="portfolioModal2"
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div className="modal-body text-center pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {/* Portfolio Modal - Title*/}
                                <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Product Details</h2>
                                {/* Icon Divider*/}
                                <div className="divider-custom">
                                    <div className="divider-custom-line"></div>
                                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                    <div className="divider-custom-line"></div>
                                </div>
                                {/* Portfolio Modal - Image*/}
                                <img className="img-fluid rounded mb-5" src={product2} alt="product2" />
                               
                                <button className="btn btn-primary" data-bs-dismiss="modal">
                                    <i className="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Portfolio Modal 3*/}
    <div className="portfolio-modal modal fade" id="portfolioModal3" tabIndex="-1" aria-labelledby="portfolioModal3"
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div className="modal-body text-center pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {/* Portfolio Modal - Title*/}
                                <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Product Details</h2>
                                {/* Icon Divider*/}
                                <div className="divider-custom">
                                    <div className="divider-custom-line"></div>
                                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                    <div className="divider-custom-line"></div>
                                </div>
                                {/* Portfolio Modal - Image*/}
                                <img className="img-fluid rounded mb-5" src={product3} alt="product3" />
                                
                                <button className="btn btn-primary" data-bs-dismiss="modal">
                                    <i className="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Portfolio Modal 4*/}
    <div className="portfolio-modal modal fade" id="portfolioModal4" tabIndex="-1" aria-labelledby="portfolioModal4"
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div className="modal-body text-center pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {/* Portfolio Modal - Title*/}
                                <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Product Details</h2>
                                {/* Icon Divider*/}
                                <div className="divider-custom">
                                    <div className="divider-custom-line"></div>
                                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                    <div className="divider-custom-line"></div>
                                </div>
                                {/* Portfolio Modal - Image*/}
                                <img className="img-fluid rounded mb-5" src={product4} alt="product4" />
                                
                                <button className="btn btn-primary" data-bs-dismiss="modal">
                                    <i className="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Portfolio Modal 5*/}
    <div className="portfolio-modal modal fade" id="portfolioModal5" tabIndex="-1" aria-labelledby="portfolioModal5"
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div className="modal-body text-center pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {/* Portfolio Modal - Title*/}
                                <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Product Details</h2>
                                {/* Icon Divider*/}
                                <div className="divider-custom">
                                    <div className="divider-custom-line"></div>
                                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                    <div className="divider-custom-line"></div>
                                </div>
                                {/* Portfolio Modal - Image*/}
                                <img className="img-fluid rounded mb-5" src={product5} alt="product5" />
                               
                                <button className="btn btn-primary" data-bs-dismiss="modal">
                                    <i className="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Portfolio Modal 6*/}
    <div className="portfolio-modal modal fade" id="portfolioModal6" tabIndex="-1" aria-labelledby="portfolioModal6"
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div className="modal-body text-center pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {/* Portfolio Modal - Title*/}
                                <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Product Details</h2>
                                {/* Icon Divider*/}
                                <div className="divider-custom">
                                    <div className="divider-custom-line"></div>
                                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                    <div className="divider-custom-line"></div>
                                </div>
                                {/* Portfolio Modal - Image*/}
                                <img className="img-fluid rounded mb-5" src={product6} alt="product6" />
                                {/* Portfolio Modal - Text*/}
                                <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Mollitia neque
                                    assumenda ipsam nihil, molestias magnam, recusandae quos quis inventore quisquam
                                    velit asperiores, vitae? Reprehenderit soluta, eos quod consequuntur itaque. Nam.
                                </p>
                                <button className="btn btn-primary" data-bs-dismiss="modal">
                                    <i className="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     {/* Portfolio Modal 7*/}
     <div className="portfolio-modal modal fade" id="portfolioModal7" tabIndex="-1" aria-labelledby="portfolioModal7"
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div className="modal-body text-center pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {/* Portfolio Modal - Title*/}
                                <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Product Details</h2>
                                {/* Icon Divider*/}
                                <div className="divider-custom">
                                    <div className="divider-custom-line"></div>
                                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                    <div className="divider-custom-line"></div>
                                </div>
                                {/* Portfolio Modal - Image*/}
                                <img className="img-fluid rounded mb-5" src={product7} alt="product7" />
                               
                                <button className="btn btn-primary" data-bs-dismiss="modal">
                                    <i className="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     {/* Portfolio Modal 8*/}
     <div className="portfolio-modal modal fade" id="portfolioModal8" tabIndex="-1" aria-labelledby="portfolioModal8"
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div className="modal-body text-center pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {/* Portfolio Modal - Title*/}
                                <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Product Details</h2>
                                {/* Icon Divider*/}
                                <div className="divider-custom">
                                    <div className="divider-custom-line"></div>
                                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                    <div className="divider-custom-line"></div>
                                </div>
                                {/* Portfolio Modal - Image*/}
                                <img className="img-fluid rounded mb-5" src={product8} alt="product8" />
                               
                                <button className="btn btn-primary" data-bs-dismiss="modal">
                                    <i className="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     {/* Portfolio Modal 9*/}
     <div className="portfolio-modal modal fade" id="portfolioModal9" tabIndex="-1" aria-labelledby="portfolioModal9"
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div className="modal-body text-center pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {/* Portfolio Modal - Title*/}
                                <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Product Details</h2>
                                {/* Icon Divider*/}
                                <div className="divider-custom">
                                    <div className="divider-custom-line"></div>
                                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                    <div className="divider-custom-line"></div>
                                </div>
                                {/* Portfolio Modal - Image*/}
                                <img className="img-fluid rounded mb-5" src={product9} alt="product9" />
                               
                                <button className="btn btn-primary" data-bs-dismiss="modal">
                                    <i className="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     {/* Portfolio Modal 10*/}
     <div className="portfolio-modal modal fade" id="portfolioModal10" tabIndex="-1" aria-labelledby="portfolioModal10"
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div className="modal-body text-center pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {/* Portfolio Modal - Title*/}
                                <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Product Details</h2>
                                {/* Icon Divider*/}
                                <div className="divider-custom">
                                    <div className="divider-custom-line"></div>
                                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                    <div className="divider-custom-line"></div>
                                </div>
                                {/* Portfolio Modal - Image*/}
                                <img className="img-fluid rounded mb-5" src={product10} alt="product10" />
                                
                                <button className="btn btn-primary" data-bs-dismiss="modal">
                                    <i className="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     {/* Portfolio Modal 11*/}
     <div className="portfolio-modal modal fade" id="portfolioModal11" tabIndex="-1" aria-labelledby="portfolioModal11"
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div className="modal-body text-center pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {/* Portfolio Modal - Title*/}
                                <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Product Details</h2>
                                {/* Icon Divider*/}
                                <div className="divider-custom">
                                    <div className="divider-custom-line"></div>
                                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                    <div className="divider-custom-line"></div>
                                </div>
                                {/* Portfolio Modal - Image*/}
                                <img className="img-fluid rounded mb-5" src={product11} alt="product11" />
                                
                                <button className="btn btn-primary" data-bs-dismiss="modal">
                                    <i className="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     {/* Portfolio Modal 12*/}
     <div className="portfolio-modal modal fade" id="portfolioModal12" tabIndex="-1" aria-labelledby="portfolioModal12"
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div className="modal-body text-center pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {/* Portfolio Modal - Title*/}
                                <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Product Details</h2>
                                {/* Icon Divider*/}
                                <div className="divider-custom">
                                    <div className="divider-custom-line"></div>
                                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                    <div className="divider-custom-line"></div>
                                </div>
                                {/* Portfolio Modal - Image*/}
                                <img className="img-fluid rounded mb-5" src={product12} alt="product12" />
                              
                                <button className="btn btn-primary" data-bs-dismiss="modal">
                                    <i className="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
       </div>
    )
}

export default GalleryModal;