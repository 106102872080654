import React from "react";

const Contactus = () => {
    return (
        <section className="page-section" id="contact">
        <div className="container">
            {/* Contact Section Heading*/}
            <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Contact US</h2>
            {/* Icon Divider*/}
            <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                <div className="divider-custom-line"></div>
            </div>
            {/* Contact Section Form*/}
            <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-7 mb-5">
                    {/* to get an API token!*/}
                    <form id="contactForm" data-sb-form-api-token="d2c5ca70-5d09-422f-8db4-3050149282ea">
                        {/* Name input*/}
                        <div className="form-floating mb-3">
                            <input className="form-control" id="name" type="text" placeholder="Enter your name..."
                                data-sb-validations="required" />
                            <label htmlFor="name">Full Name</label>
                            <div className="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                        </div>
                        {/* Email address input*/}
                        <div className="form-floating mb-3">
                            <input className="form-control" id="email" type="email" placeholder="name@example.com"
                                data-sb-validations="required,email" />
                            <label htmlFor="email">Email Address</label>
                            <div className="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                            <div className="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                        </div>
                        {/* Phone number input*/}
                        <div className="form-floating mb-3">
                            <input className="form-control" id="phone" type="tel" placeholder="(123) 456-7890"
                                data-sb-validations="required" />
                            <label htmlFor="phone">Phone Number</label>
                            <div className="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.
                            </div>
                        </div>
                        {/* Message input*/}
                        <div className="form-floating mb-3">
                            <textarea className="form-control" id="message" type="text"
                                placeholder="Enter your message here..." style={{height: "10rem"}}
                                data-sb-validations="required"></textarea>
                            <label htmlFor="message">Message</label>
                            <div className="invalid-feedback" data-sb-feedback="message:required">A message is required.
                            </div>
                        </div>
                        {/* Submit success message*/}
                       
                        <div className="d-none" id="submitSuccessMessage">
                            <div className="text-center mb-3">
                                <div className="fw-bolder">Form submission successful!</div>
                                To activate this form, sign up at
                                <br />
                                <a
                                    href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                            </div>
                        </div>
                        {/* Submit error message*/}
                        
                        <div className="d-none" id="submitErrorMessage">
                            <div className="text-center text-danger mb-3">Error sending message!</div>
                        </div>
                        {/* Submit Button*/}
                        <button className="btn btn-primary btn-xl disabled" id="submitButton" type="submit">Send</button>
                    </form>
                </div>
                <div className="col-lg-4 col-xl-5">
                <iframe className="mb-2" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15364.08235046467!2d80.2406246!3d15.6970586!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4a5159a5e87201%3A0x9bdb422315b3a91d!2sN%20Mart!5e0!3m2!1sen!2sus!4v1695776844049!5m2!1sen!2sus" width="100%" height="350"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="N Mart Map"></iframe>
                <div className="col-lg-12 mb-5">
                    <h4 className="text-uppercase mb-4">Social Media</h4>
                    <a className="btn btn-outline-success btn-social mx-1" href="https://chat.whatsapp.com/DwGGRSs1lKPLwQDEWa1Gdx" target="_blank"  rel="noopener noreferrer"><i
                            className="fab fa-fw fa-brands fa-whatsapp"></i></a>
                    <a className="btn btn-outline-danger btn-social mx-1" href="https://www.instagram.com/nmartcjm/" target="_blank"  rel="noopener noreferrer"><i className="fab fa-fw fa-brands fa-instagram"></i></a>
                   
                </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Contactus;