import React from "react";
import product1 from "../src/assets/images/Products/product1.png"
import product2 from "../src/assets/images/Products/product2.png"
import product3 from "../src/assets/images/Products/product3.png"
import product4 from "../src/assets/images/Products/product4.png"
import product5 from "../src/assets/images/Products/product5.png"
import product6 from "../src/assets/images/Products/product6.png"
import product7 from "../src/assets/images/Products/product7.png"
import product8 from "../src/assets/images/Products/product8.png"
import product9 from "../src/assets/images/Products/product9.png"
import product10 from "../src/assets/images/Products/product10.png"
import product11 from "../src/assets/images/Products/product11.png"
import product12 from "../src/assets/images/Products/product12.png"

const Gallery = () => {
    return (
        <section className="page-section bg-warning portfolio" id="products">
        <div className="container">
            {/* Portfolio Section Heading */}
            <h2 className="page-section-heading text-center text-uppercase text-dark mb-0">Products</h2>
           {/* Icon Divider*/}
            <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                <div className="divider-custom-line"></div>
            </div>
           {/* Portfolio Grid Items*/}
            <div className="row justify-content-center">
               {/* Portfolio Item 1*/}
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal1">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid" src={product1} alt="product1" />
                    </div>
                </div>
               {/* Portfolio Item 2*/}
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal2">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid" src={product2} alt="product2" />
                    </div>
                </div>
               {/* Portfolio Item 3*/}
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal3">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid" src={product3} alt="product3" />
                    </div>
                </div>
               {/* Portfolio Item 4*/}
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal4">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid" src={product4} alt="product4" />
                    </div>
                </div>
               {/* Portfolio Item 5*/}
               <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal5">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid" src={product5} alt="product5" />
                    </div>
                </div>
               {/* Portfolio Item 6*/}
               <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal6">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid" src={product6} alt="product6" />
                    </div>
                </div>
                {/* Portfolio Item 7*/}
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal7">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid" src={product7} alt="product7" />
                    </div>
                </div>
                {/* Portfolio Item 8*/}
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal8">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid" src={product8} alt="product8" />
                    </div>
                </div>
                {/* Portfolio Item 9*/}
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal9">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid" src={product9} alt="product9" />
                    </div>
                </div>
                {/* Portfolio Item 10*/}
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal10">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid" src={product10} alt="product10" />
                    </div>
                </div>
                {/* Portfolio Item 11*/}
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal11">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid" src={product11} alt="product11" />
                    </div>
                </div>
                {/* Portfolio Item 12*/}
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal12">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid" src={product12} alt="product12" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
export default Gallery;